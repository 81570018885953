/* eslint-disable import/no-unresolved */
import { useContext } from 'react';

import { BreadCrumbs } from '../ui/breadCrumbs';
import { Button } from '../ui/button';
import { LazyImageWithPlaceholder2 } from '../ui/lazy-image-with-placeholder2';
import { ContactContext } from '@/utils';

import './index.scss';

export const Intro = ({ page }) => {
  const { toggleContactModal } = useContext(ContactContext);

  return (
    <>
      {page === 'home' && (
        <section className="intro intro--home">
          <LazyImageWithPlaceholder2
            className="intro__bgImage"
            srcSet={require('@/assets/images/intro-home.webp?srcset')}
            placeholder={require('@/assets/images/intro-home.webp?placeholder')}
            alt="Excellence in Custom Software Engineering"
            longAnimation
          />
          <div className="container intro__inner">
            <h1 className="intro__title">
              Excellence in Custom <span>Software Engineering</span>
            </h1>
            <p className="intro__text">
              IT Consulting company, from ideas and optimization to delivery.
            </p>
            <Button className="intro__btn" text="Get a free quote" onClick={toggleContactModal} />
          </div>
        </section>
      )}
      {page === 'services' && (
        <section className="intro intro--services">
          <LazyImageWithPlaceholder2
            className="intro__bgImage"
            srcSet={require('@/assets/images/intro-services.webp?srcSet')}
            placeholder={require('@/assets/images/intro-services.webp?placeholder')}
            alt="Our services"
            longAnimation
          />
          <BreadCrumbs theme="dark" />
          <div className="container intro__inner">
            <h1 className="intro__title">
              Our <span>Services</span>
            </h1>
            <p className="intro__text">
              UUUSoftware offers almost unlimited possibilities for companies of any scale. Our work
              goes beyond custom software development.
            </p>
            <p className="intro__text">
              Powered by years of experience and talented personnel, UUUSoftware is always there to
              help you with any technical issue.
            </p>
            <Button className="intro__btn" text="Get a free quote" onClick={toggleContactModal} />
          </div>
        </section>
      )}
      {page === 'reactjs' && (
        <section className="intro intro--reactjs">
          <LazyImageWithPlaceholder2
            className="intro__bgImage"
            srcSet={require('@/assets/images/intro-reactjs.webp?srcSet')}
            placeholder={require('@/assets/images/intro-reactjs.webp?placeholder')}
            alt="Custom React Development"
            longAnimation
          />
          <BreadCrumbs theme="dark" />
          <div className="container intro__inner">
            <h1 className="intro__title">
              Custom React <span>Development</span>
            </h1>
            <p className="intro__text">
              UUUSoftware builds dedicated teams of React developers to bring your project to the
              next level. Our senior-level specialists leverage their skills to make your business
              grow and scale.
            </p>
            <Button className="intro__btn" text="Get a free quote" onClick={toggleContactModal} />
          </div>
        </section>
      )}
      {page === 'webDev' && (
        <section className="intro intro--webDev">
          <LazyImageWithPlaceholder2
            className="intro__bgImage"
            srcSet={require('@/assets/images/intro-web-dev.webp?srcSet')}
            placeholder={require('@/assets/images/intro-web-dev.webp?placeholder')}
            alt="Web Development"
            longAnimation
          />
          <BreadCrumbs theme="dark" />
          <div className="container intro__inner">
            <h1 className="intro__title">
              Custom Web <span>Development Solutions</span>
            </h1>
            <p className="intro__text">
              UUUSoftware strength resides in its ability to transform your requirements into a
              complete solution efficient for your business needs and end customers.
            </p>
            <p className="intro__text">
              Our approach, powered by the latest and future-proof tech stacks, is backed by
              innovations in the fullest sense of the word.
            </p>
            <Button className="intro__btn" text="Get a free quote" onClick={toggleContactModal} />
          </div>
        </section>
      )}
      {page === 'mobileDev' && (
        <section className="intro intro--mobileDev">
          <LazyImageWithPlaceholder2
            className="intro__bgImage"
            srcSet={require('@/assets/images/intro-mobile-dev.webp?srcSet')}
            placeholder={require('@/assets/images/intro-mobile-dev.webp?placeholder')}
            alt="Mobile App Development"
            longAnimation
          />
          <BreadCrumbs theme="dark" />
          <div className="container intro__inner">
            <h1 className="intro__title">
              Mobile App <span>Development</span>
            </h1>
            <p className="intro__text">
              UUUSoftware knows how to impress your clients with a powerful combination of
              user-friendly design and the latest technologies. Every mobile app we develop
              transforms into a secure and competitive product.
            </p>
            <Button className="intro__btn" text="Get a free quote" onClick={toggleContactModal} />
          </div>
        </section>
      )}
      {page === 'devOps' && (
        <section className="intro intro--devOps">
          <LazyImageWithPlaceholder2
            className="intro__bgImage intro__bgImage_large"
            srcSet={require('@/assets/images/intro-devops-large.jpg?srcSet')}
            placeholder={require('@/assets/images/intro-devops-large.jpg?placeholder')}
            alt="Cloud DevOps"
            longAnimation
          />
          <LazyImageWithPlaceholder2
            className="intro__bgImage intro__bgImage_default"
            srcSet={require('@/assets/images/intro-devops.webp?srcSet')}
            placeholder={require('@/assets/images/intro-devops.webp?placeholder')}
            alt="Cloud DevOps"
          />
          <BreadCrumbs theme="dark" />
          <div className="container intro__inner">
            <h1 className="intro__title">
              Cloud Migrations and <span>DevOps</span>
            </h1>
            <p className="intro__text">
              UUUSoftware adopts the best DevOps practices to ensure a smooth app delivery to
              production. Our experienced DevOps team provides automation for every area of your
              project — code, database changes, and infrastructure.
            </p>
            <Button className="intro__btn" text="Get a free quote" onClick={toggleContactModal} />
          </div>
        </section>
      )}
      {page === 'ux-ui' && (
        <section className="intro intro--ux-ui">
          <LazyImageWithPlaceholder2
            className="intro__bgImage"
            srcSet={require('@/assets/images/intro-ux-ui.webp?srcSet')}
            placeholder={require('@/assets/images/intro-ux-ui.webp?placeholder')}
            alt="UX UI"
            longAnimation
          />
          <BreadCrumbs theme="dark" title="UX and UI" />
          <div className="container intro__inner">
            <h1 className="intro__title">
              <span>UX and UI</span> are key
            </h1>
            <p className="intro__text">
              With over 15 years of experience in UI and UX design, UUUSoftware can help you develop
              software that users will truly want to use.
            </p>
            <p className="intro__text">
              We incorporate our deep expertise in user behavior by collecting and analyzing your
              business goals and target audience.
            </p>
            <Button className="intro__btn" text="Get a free quote" onClick={toggleContactModal} />
          </div>
        </section>
      )}
      {page === 'qaAndTesting' && (
        <section className="intro intro--qa">
          <LazyImageWithPlaceholder2
            className="intro__bgImage"
            srcSet={require('@/assets/images/intro-qa.webp?srcSet')}
            placeholder={require('@/assets/images/intro-qa.webp?placeholder')}
            alt="QA and Testing"
            longAnimation
          />
          <BreadCrumbs theme="dark" title="QA Automation and Testing" />
          <div className="container intro__inner">
            <h1 className="intro__title">
              Automation in<span> Quality Assurance and Testing</span>
            </h1>
            <p className="intro__text">
              UUUSoftware&apos;s tried-and-tested approach empowers our dedicated QA teams to look
              at each situation objectively, identify its challenges, and address them as
              efficiently as possible to assure the success of your software.
            </p>
            <Button className="intro__btn" text="Get a free quote" onClick={toggleContactModal} />
          </div>
        </section>
      )}
      {page === 'onshore' && (
        <section className="intro intro--onshore">
          <LazyImageWithPlaceholder2
            className="intro__bgImage"
            srcSet={require('@/assets/images/intro-onshore-and-offshore-staffing.webp?srcSet')}
            placeholder={require('@/assets/images/intro-onshore-and-offshore-staffing.webp?placeholder')}
            alt="Onshore and Offshore"
            longAnimation
          />
          <BreadCrumbs theme="dark" />
          <div className="container intro__inner">
            <h1 className="intro__title">
              <span>Onshore &</span>
              <br />
              Offshore Staffing
            </h1>
            <p className="intro__text">
              UUUSoftware helps companies find top talent for their open positions. We can assemble
              onshore and offshore teams that fit your project requirements and convenient time
              zone.
            </p>
            <Button className="intro__btn" text="Get a free quote" onClick={toggleContactModal} />
          </div>
        </section>
      )}
      {page === 'company' && (
        <section className="intro intro--company">
          <LazyImageWithPlaceholder2
            className="intro__bgImage"
            srcSet={require('@/assets/images/intro-company.webp?srcSet')}
            placeholder={require('@/assets/images/intro-company.webp?placeholder')}
            alt="About Company"
            longAnimation
          />
          <BreadCrumbs theme="dark" />
          <div className="container intro__inner">
            <h1 className="intro__title">
              About <span>Company</span>
            </h1>
            <p className="intro__text">
              We are UUUSoftware. Our specialization lies in the areas of software development and
              cloud-based solutions. We are fond of what we do and are experts in our field. With
              years of experience in custom software development, we ensure our clients receive the
              most from our services.
            </p>
            <p className="intro__text">
              UUUSoftware is your reliable partner in matters of cloud solutions, staff
              augmentation, web and mobile app development, UX/UI design, and QA.
            </p>
            <Button className="intro__btn" text="Get a free quote" onClick={toggleContactModal} />
          </div>
        </section>
      )}
    </>
  );
};
